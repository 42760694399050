export default {
  "screen": "scherm",
  "questions": "vragen",
  "questionTypes": {
    "Short": "tekst regel",
    "Paragraph": "Tekst paragraaf",
    "Multiple": "Multiple choice",
    "Checkboxes": "Checkboxen",
    "Dropdown": "Dropdown",
    "DoubleRadios": "Dubbele radio's",
    "HuidigGewenst": "Huidig / Gewenst",
    "ColumnSort": "Column sorteren",
    "TextOnly": "Content: Tekst",
    "Markdown": "Content: Markdown",
    "Html": "Content: HTML",
  },
  "addOption": "optie toevoegen",
  "choiceRequirement": "Kies een voorwaarde",
  "requirement": [""],
  "questionType": "Vraagtype"
}