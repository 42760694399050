import http from './http'
import { encode } from 'js-base64'
import Store from '@/store'
import { EntryJson } from '@/models/Models'
// Whole API to functions

interface AppResponse {
  success: boolean
  data: any
}

interface AppResponseTyped<T> {
  success: boolean
  data: T
}

export interface ScreenData {
  screenId: string
  answers: Array<QuestionAnswer>
}

export interface QuestionAnswer {
  questionId: string
  answerRaw: string
}

// GroupUser*
export interface GroupUser {
  appUserId: number
  displayName: string
  userConnected: boolean
  connectIdenitier: string
  permissions: { name: string; value: string }[]
}

export interface CreateGroupUser {
  displayName: string
}

// User / auth
export const userTryConnect = (connectId: string) => http.get<AppResponse>('UserConnect', { params: { connectId } })
export const getUserAsync = () => http.get<AppResponse>('GetUser', { params: {} })

// Group
export const getGroupUsersAsync = () => http.get<AppResponseTyped<GroupUser[]>>('getGroupUsers', { params: {} })
export const getGroupUserAsync = (id: string) =>
  http.get<AppResponseTyped<GroupUser>>('getGroupUser', { params: { id } })
export const updateGroupUserAsync = (data: GroupUser) => http.post<AppResponse>('UpdateGroupUser', data)

export const createGroupUserAsync = (data: CreateGroupUser) => http.post<AppResponse>('CreateGroupUser', data)
export const deleteGroupUserAsync = (id: string) => http.get<AppResponse>('deleteGroupUser', { params: { id } })

//
export const getDashboardStatsAsync = () => http.get<AppResponse>('GetDashboardStats', { params: {} })

// Template
export const getTemplatesAsync = () => http.get<AppResponse>('GetSurveyTemplates', { params: {} })
export const getTemplateAsync = (id: string) => http.get<AppResponse>('GetSurveyTemplate', { params: { id } })
export const updateTemplateAsync = (id: string, data: object) =>
  http.post<AppResponse>('UpdateSurveyTemplate', data, { params: { id } })
export const createTemplateAsync = () => http.post<AppResponse>('CreateSurveyTemplate')
export const deleteTemplateAsync = (id: string) => http.get<AppResponse>('DeleteSurveyTemplate', { params: { id } })

// Entry
export const getEntriesAsync = () => http.get<AppResponse>('GetEntries')
export const getEntryAsync = (id: string) => http.get<AppResponse>('GetEntry', { params: { id } })
export const createEntryAsync = (data: EntryJson) => http.post<AppResponse>('CreateEntry', data)

// Admin
interface CreateGroup {
  groupName: string
}
interface UpdateGroupData {
  groupId: number
}
interface UpdateUser {
  appUserId: number
  groupId: number
}
export const getGroupData = () => http.get<AppResponse>('GetGroupData')
export const getGroups = () => http.get<AppResponse>('GetGroups')
export const createGroup = (data: CreateGroup) => http.post<AppResponse>('CreateGroup', data)
export const updateGroupData = (data: UpdateGroupData) => http.post<AppResponse>('UpdateGroupData', data)
export const searchUsers = (query: string) => http.get<AppResponse>('SearchUsers', { params: { query } })
export const updateUser = (data: UpdateUser) => http.post<AppResponse>('UpdateUser', data)

// UNUSED (for now)
export const refreshStoreData = async () => {
  const resp = await getTemplatesAsync()
  Store.templates = resp.data
}

export const getScreenAsync = (
  surveyId: string,
  origin: string,
  requested: string,
  direction: string,
  answers: Array<QuestionAnswer>
) =>
  http.post<AppResponse>(
    'GetScreen',
    encode(
      JSON.stringify({
        CurrentScreen: {
          SurveyId: surveyId,
          OriginScreenId: origin,
          RequestedScreenId: requested,
          RequestedDirection: direction
        },
        Answers: answers
      })
    ),
    { headers: { 'Content-Type': 'text/plain' } }
  )

export const postSurveyAsync = (surveyId: string, answers: Array<QuestionAnswer>) =>
  http.post<AppResponse>(
    'SubmitAnswers',
    encode(
      JSON.stringify({
        SurveyId: surveyId,
        Answers: answers
      })
    ),
    { headers: { 'Content-Type': 'text/plain' } }
  )
