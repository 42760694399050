import lang from "./nl"

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function t(x, upperCase = false, amount = -1) {
  let s = x.split('.').reduce((o, i) => o[i], lang) ?? "[?]"
  if (amount > -1) s = 'not supported'
  if (upperCase) s = capitalizeFirstLetter(s)
  return s
}