<template>
  <div class="fixed inset-0 z-50 bg-slate-800 opacity-90" v-show="CurrentAppState.appBackdrop"></div>
  <div
    class="fixed inset-0 z-0 overflow-y-scroll bg-slate-100 font-body"
    :class="{ blur: CurrentAppState.appBackdrop }"
  >
    <div v-if="CurrentAppState.appLoading" class="flex h-screen flex-col items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        class="m-auto block h-[95px] w-[95px] animate-pulse"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <g transform="translate(26.5,26.5)">
          <rect x="-20.5" y="-20.5" width="41" height="41" fill="#cc1042">
            <animateTransform
              attributeName="transform"
              type="scale"
              repeatCount="indefinite"
              dur="0.9615384615384615s"
              keyTimes="0;1"
              values="1.1;1"
              begin="-0.28846153846153844s"
            ></animateTransform>
          </rect>
        </g>
        <g transform="translate(73.5,26.5)">
          <rect x="-20.5" y="-20.5" width="41" height="41" fill="#0073be">
            <animateTransform
              attributeName="transform"
              type="scale"
              repeatCount="indefinite"
              dur="0.9615384615384615s"
              keyTimes="0;1"
              values="1.1;1"
              begin="-0.19230769230769232s"
            ></animateTransform>
          </rect>
        </g>
        <g transform="translate(26.5,73.5)">
          <rect x="-20.5" y="-20.5" width="41" height="41" fill="#ffa82c">
            <animateTransform
              attributeName="transform"
              type="scale"
              repeatCount="indefinite"
              dur="0.9615384615384615s"
              keyTimes="0;1"
              values="1.1;1"
              begin="0s"
            ></animateTransform>
          </rect>
        </g>
        <g transform="translate(73.5,73.5)">
          <rect x="-20.5" y="-20.5" width="41" height="41" fill="#aacd2b">
            <animateTransform
              attributeName="transform"
              type="scale"
              repeatCount="indefinite"
              dur="0.9615384615384615s"
              keyTimes="0;1"
              values="1.1;1"
              begin="-0.09615384615384616s"
            ></animateTransform>
          </rect>
        </g>
      </svg>
    </div>
    <div v-else-if="CurrentAppState.appError" class="flex h-screen flex-col items-center justify-center">
      <div class="mb-2 flex flex-col items-center text-red-500">
        <EmojiSadIcon class="mr-2 h-10 w-10" />
        <div class="text-lg font-bold">Er ging iets niet helemaal goed</div>
      </div>
      <div class="text-slate-600">Probeer de pagina opnieuw te laden.</div>
      <ul class="mt-4 text-xs text-red-500" v-show="false">
        <li v-for="(error, i) in CurrentAppState.appErrors" :key="i">{{ error.message }}</li>
      </ul>
    </div>
    <div v-else-if="!store.user.isLoggedIn" class="">
      <div class="container mx-auto mt-8 w-[400px] rounded-sm bg-white p-4 shadow">
        <div class="mb-4 flex items-center justify-center">
          <img src="/Calanza.svg" class="mr-1 w-12" />
          <h1 class="mt-3 mb-1 text-xl font-bold text-slate-600">Calanza Panelizer</h1>
        </div>
        <h1 class="mt-1 mb-2 text-center text-lg font-bold text-slate-700">Inloggen</h1>

        <div class="mt-3 flex flex-col items-center gap-3">
          <button class="inline-block p-[3px] transition-all hover:bg-slate-400" @click="loginWithAAD">
            <img class="" src="/MicrosoftSignIn.svg" />
          </button>

          <button @click="helpOpen = true" class="text-sm text-slate-500 hover:text-blue-600 hover:underline">
            Hulp bij inloggen
          </button>
        </div>
      </div>

      <div v-if="helpOpen" class="container mx-auto mt-8 w-[400px] rounded-sm bg-white p-4 shadow">
        <h1 class="mt-1 mb-2 text-center text-lg font-bold text-slate-700">Hulp bij inloggen</h1>
        <p class="text-slate-700">Hier hulp plaatsen</p>
      </div>
    </div>
    <div v-else>
      <div v-if="CurrentAppState.appLoadingSoft">
        <div class="fixed inset-0 z-0 overflow-y-scroll bg-slate-300 bg-opacity-70 font-body">
          <div class="flex h-screen flex-col items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              class="m-auto block h-[95px] w-[95px] animate-pulse"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <g transform="translate(26.5,26.5)">
                <rect x="-20.5" y="-20.5" width="41" height="41" fill="#cc1042">
                  <animateTransform
                    attributeName="transform"
                    type="scale"
                    repeatCount="indefinite"
                    dur="0.9615384615384615s"
                    keyTimes="0;1"
                    values="1.1;1"
                    begin="-0.28846153846153844s"
                  ></animateTransform>
                </rect>
              </g>
              <g transform="translate(73.5,26.5)">
                <rect x="-20.5" y="-20.5" width="41" height="41" fill="#0073be">
                  <animateTransform
                    attributeName="transform"
                    type="scale"
                    repeatCount="indefinite"
                    dur="0.9615384615384615s"
                    keyTimes="0;1"
                    values="1.1;1"
                    begin="-0.19230769230769232s"
                  ></animateTransform>
                </rect>
              </g>
              <g transform="translate(26.5,73.5)">
                <rect x="-20.5" y="-20.5" width="41" height="41" fill="#ffa82c">
                  <animateTransform
                    attributeName="transform"
                    type="scale"
                    repeatCount="indefinite"
                    dur="0.9615384615384615s"
                    keyTimes="0;1"
                    values="1.1;1"
                    begin="0s"
                  ></animateTransform>
                </rect>
              </g>
              <g transform="translate(73.5,73.5)">
                <rect x="-20.5" y="-20.5" width="41" height="41" fill="#aacd2b">
                  <animateTransform
                    attributeName="transform"
                    type="scale"
                    repeatCount="indefinite"
                    dur="0.9615384615384615s"
                    keyTimes="0;1"
                    values="1.1;1"
                    begin="-0.09615384615384616s"
                  ></animateTransform>
                </rect>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <TopBar v-if="$route.meta.topBarShow" />
      <div :class="{ 'mt-16': $route.meta.topBarShow }">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import TopBar from './components/TopBar.vue'
import { CurrentAppState } from '@/main'
import { EmojiSadIcon } from '@heroicons/vue/outline'
import { getUserAsync, refreshStoreData } from './data'
import store from '@/store'
import { useRouter } from 'vue-router'

const router = useRouter()

function checkConnect() {
  if (store.user.isLoggedIn == true && store.user.isConnected == false && router.currentRoute.value.name != 'connect')
    router.replace('connect')
}

async function getUserinfo() {
  const response = await getUserAsync()

  store.user = response.data

  checkConnect()
}

getUserinfo().then(() => {
  if (CurrentAppState.appError) {
    CurrentAppState.appLoading = false
  } else if (store.user.isLoggedIn && store.user.isConnected) {
    refreshStoreData().then(() => (CurrentAppState.appLoading = false))
  } else {
    CurrentAppState.appLoading = false
  }
})

function loginWithAAD() {
  if (location.hostname == 'localhost') {
    store.user = {
      isLoggedIn: true,
      isConnected: true,
      identityProvider: 'FAKE',
      userId: '1',
      userName: 'fake_user@demo.nl',
      permissions: ['Admin']
    }
  } else {
    window.location =
      '/.auth/login/aad?post_login_redirect_uri=https://happy-moss-071adef03.1.azurestaticapps.net/?code=cas'
  }
  refreshStoreData()
  checkConnect()
}

const helpOpen = ref(false)
</script>
<style></style>
