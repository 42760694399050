import { ClientPrincipal, TemplateJson } from './../models/Models'
import { reactive } from 'vue'

export interface IStore {
  user: ClientPrincipal
  templates: Array<TemplateJson>
}

const store: IStore = reactive({
  user: { isLoggedIn: false, isConnected: false, identityProvider: '', userId: '', userName: '', permissions: [] },
  templates: []
})

export function hasPermission(permissionName: string) {
  return store.user.permissions.includes('Admin') || store.user.permissions.includes(permissionName)
}

export default store
