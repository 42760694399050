import axios from 'axios'
import { ref } from 'vue'
import router from '@/router'
import { CurrentAppState } from '@/main'
import { decode } from 'js-base64'

const minutes2ms = (minutes: number): number => 60000 * minutes

export const GlobalAxiosError = ref(0)

declare module 'axios' {
  export interface AxiosInstance {
    request<T = any>(config: AxiosRequestConfig): Promise<T>
    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
    put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
    patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
  }
}

const instance = axios.create({
  withCredentials: true,
  baseURL: '/api/',
  timeout: minutes2ms(1),
  headers: {
    'calanza-tracking': 'dev'
  },
  validateStatus: function (status) {
    if (status >= 200 && status < 300) return true
    if (status == 400) return true
    if (status == 401) {
      // 401 = Unauthorized
      //GlobalAxiosError.value = null;
      router.replace({ name: 'Login' })
      return true
    }

    if (status == 500) {
      CurrentAppState.appError = true
    }

    return false
  }
})
instance.interceptors.response.use(
  (response) => {
    if (response.data && response.data.data == 'Unauthorized') router.replace('/?code=unauthorized')
    return response.data //JSON.parse(decode(response.data))
  },
  (error) => {
    CurrentAppState.appErrors.push(error)
    return {
      success: false,
      data: null
    }
  }
)

export default instance
