import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { topBarShow: false },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    meta: { topBarShow: true, topBarTitle: 'Admin' },
    component: () => import('../views/AdminView.vue')
  },
  {
    path: '/connect/:connectId?',
    name: 'connect',
    meta: { topBarShow: false, topBarTitle: 'Account verbinden' },
    component: () => import('../views/ConnectView.vue')
  },
  {
    path: '/uw-account/instellingen',
    name: 'settings',
    meta: { topBarShow: true, topBarTitle: 'Instellingen' },
    component: () => import('../views/SettingsView.vue')
  },
  {
    path: '/surveys',
    name: 'surveys',
    meta: { topBarShow: true, topBarTitle: 'Onderzoeken' },
    component: () => import(/* webpackChunkName: "about" */ '../views/SurveysIndex.vue')
  },
  {
    path: '/surveys/create',
    name: 'surveysCreate',
    meta: { topBarShow: true, topBarTitle: 'Onderzoek aanmaken', topBarBackRouteName: 'surveys' },
    component: () => import(/* webpackChunkName: "about" */ '../views/SurveysCreate.vue')
  },
  {
    path: '/surveys/:id',
    name: 'surveysSingle',
    meta: { topBarShow: true, topBarTitle: 'Onderzoek', topBarBackRouteName: 'surveys' },
    component: () => import(/* webpackChunkName: "surveys_single" */ '../views/SurveysSingle.vue')
  },
  {
    path: '/survey-templates',
    name: 'surveyTemplates',
    meta: { topBarShow: true, topBarTitle: 'Onderzoek templates' },
    component: () => import('../views/SurveyTemplatesIndex.vue')
  },
  {
    path: '/survey-templates/:id',
    name: 'surveyTemplatesEdit',
    meta: { topBarShow: true, topBarTitle: 'Onderzoek template bewerken', topBarBackRouteName: 'surveyTemplates' },
    component: () => import('../views/SurveyTemplatesEdit.vue')
  },
  {
    path: '/reports',
    name: 'reports',
    meta: { topBarShow: true, topBarTitle: 'Onderzoek rapportage overzicht' },
    component: () => import('../views/ReportsIndex.vue')
  },
  {
    path: '/gebruikers',
    name: 'users',
    meta: { topBarShow: true, topBarTitle: 'Gebruikers overzicht' },
    component: () => import('../views/Group/UsersIndex.vue')
  },
  {
    path: '/gebruikers/:id',
    name: 'userEdit',
    meta: { topBarShow: true, topBarTitle: 'Gebruiker aanpassen' },
    component: () => import('../views/Group/UserEdit.vue')
  },
  {
    path: '/gebruikers/nieuwe-gebruiker',
    name: 'userCreate',
    meta: { topBarShow: true, topBarTitle: 'Nieuwe gebruiker maken' },
    component: () => import('../views/Group/UserCreate.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
