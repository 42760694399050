import { createApp, reactive } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import { t } from './lang'
import './index.css'
import Vue from 'vue'

if (console) {
  setTimeout(() => {
    console.log('%c\nLET OP!!\n', 'color: red; font-weight: bold; font-size: 24px')
    console.log(
      '%c\nDoor hier iets te kopiëren/plakken kunnen aanvallers toegang krijgen tot uw gegevens.\n',
      'color: black; font-size: 15px; font-family:sans-serif;'
    )
  }, 500)
}

type AppState = {
  appLang: string
  appLoading: boolean
  appLoadingSoft: boolean
  appBackdrop: boolean
  appError: boolean
  appErrors: string[]
}
export const CurrentAppState: AppState = reactive({
  appLang: 'en',
  appLoading: true,
  appLoadingSoft: false,
  appBackdrop: false,
  appError: false,
  appErrors: []
})

const app = createApp(App)

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    t(key: string, upperCase?: boolean, amount?: number): string
  }
}
app.config.globalProperties.t = t

// Plugins
import ClickOutside from '@/plugins/v-click-outside'
app.use(ClickOutside)

// Register UI elements
import UI_Button from '@/components/ButtonComponent.vue'
app.component('ui-button', UI_Button)

// Add router; then mount to #app
app.use(router).mount('#app')
