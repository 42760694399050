<template>
  <div>
    <div class="absolute top-0 right-0 left-0 max-w-full overflow-hidden bg-slate-300 shadow">
      <div class="flex items-center">
        <div class="w-48">
          <router-link
            :to="{ name: $route.meta.topBarBackRouteName ?? 'home' }"
            class="group flex items-center p-2 text-slate-600 group-hover:text-slate-800"
          >
            <div class="rounded-full p-2 group-hover:bg-slate-400 group-hover:text-slate-300">
              <ChevronLeftIcon class="h-5 w-5" />
            </div>
            <span class="pl-1 text-sm uppercase text-slate-500">terug</span>
          </router-link>
        </div>
        <div class="text-slate-700">{{ $route.meta.topBarTitle }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { ChevronLeftIcon } from '@heroicons/vue/solid'
export default {
  components: {
    ChevronLeftIcon
  }
}
</script>
